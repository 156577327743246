<template>
    <PageSlide v-bind="slidePassthrough"
               :class="classes"
               @in="scrollIn"
               class="feature-slide"
    >
        <BaseBox padding="large"
                 class="feature-slide__text py-0"
        >
            <BContainer>
                <BCol v-bind="textColumnAttributes">
                    <BaseBox ref="text"
                             padding="large"
                             class="feature-slide__text__box pt-0 px-0"
                    >
                        <h2 v-html="title"
                            class="feature-slide__text__title h1"
                        ></h2>
                        <div v-html="summary"
                           class="feature-slide__text__summary"
                        ></div>
                        <div v-if="btn"
                             class="feature-slide__text__btn"
                        >
                            <BaseBtn v-bind="btnPassthrough">
                                {{ btn.text }}
                            </BaseBtn>
                        </div>
                    </BaseBox>
                </BCol>
            </BContainer>
        </BaseBox>
        <div class="feature-slide__asset">
            <DeviceBrowserDesktop>
                <component v-if="assetComponent"
                           :is="assetComponent"
                           :src="asset.src"
                           ref="asset"
                />
            </DeviceBrowserDesktop>
        </div>
    </PageSlide>
</template>

<script>
    import PageSlide from './PageSlide';
    import DeviceBrowserDesktop from '../devices/DeviceBrowserDesktop';

    export default {
        components: {
            PageSlide,
            DeviceBrowserDesktop
        },

        props: {
            backgroundColour: String,
            textColour: String,
            asset: Object,
            title: String,
            summary: String,
            btn: Object,
            textPosition: {
                type: String,
                default: 'left'
            }
        },

        computed: {
            slidePassthrough() {
                return {
                    backgroundColour: this.backgroundColour,
                    textColour: this.textColour
                }
            },

            btnPassthrough() {
                let attributes = Object.assign({}, this.btn);
                delete attributes.text;
                return attributes;
            },

            classes() {
                return [`feature-slide--text-${this.textPosition}`];
            },

            textColumnAttributes() {
                let attributes = {
                    lg: 3,
                    xl: 2,
                    offsetXl: 1,
                };

                if (this.textPosition === 'right') {
                    attributes.offsetLg = 5;
                    attributes.offsetXl = 5;
                }

                return attributes;
            },

            assetComponent() {
                switch (this.asset.type) {
                    case 'image':
                        return 'BaseImg';

                    case 'video':
                        return 'BaseVideo';

                    default:
                        return null;
                }
            }
        },

        methods: {
            scrollIn() {
                if (this.asset.type === 'video') {
                    this.$refs.asset.play();
                }
            }
        }
    }
</script>

<style lang="scss">
    .feature-slide {
        padding-top: 50px;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding-top: 110px;
        }

        &--text-left, &--text-right {
            .feature-slide__text {
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                }

                .feature-slide__text__box {
                    @include media-breakpoint-up(lg) {
                        padding: 0;
                    }
                }
            }

            .feature-slide__asset {
                @include media-breakpoint-up(lg) {
                    margin-top: 55px;
                }

                @include media-breakpoint-up(xl) {
                    margin-top: 80px;
                }
            }
        }

        &--text-left {
            .feature-slide__text {
                &__title, &__summary, &__btn {
                    @include media-breakpoint-up(lg) {
                        transform: translateX(-100px);
                    }
                }
            }

            .feature-slide__asset {
                padding-left: 40px;

                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                    margin-left: auto;
                }
            }
        }

        &--text-right {
            .feature-slide__text {
                text-align: right;

                .feature-slide__text__box {
                    direction: rtl;
                }

                &__title, &__summary, &__btn {
                    @include media-breakpoint-up(lg) {
                        transform: translateX(100px);
                    }
                }
            }

            .feature-slide__asset {
                padding-right: 40px;

                @include media-breakpoint-up(lg) {
                    padding-right: 0;
                }
            }
        }

        &__text {
            @include media-breakpoint-up(lg) {
                margin-top: 110px;
            }

            &__box {
                padding-top: 30px;
                padding-bottom: 30px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 10px;
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }

            &__title, &__summary, &__btn {
                opacity: 0;
                transform: translateY(100px);
                transition: opacity 400ms, transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &__summary {
                transition-delay: 100ms;
            }

            &__btn {
                transition-delay: 200ms;
            }
        }

        &__asset {
            opacity: 0;
            transform: translateY(100px);
            transition: opacity 500ms 300ms, transform 500ms 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
            display: flex;
            flex-grow: 1;

            @include media-breakpoint-up(lg) {
                width: 50%;
                position: static;
            }

            .device-browser {
                width: 100%;
            }
        }

        &.page-slide--in {
            .feature-slide__text__title, .feature-slide__text__summary, .feature-slide__text__btn, .feature-slide__asset {
                opacity: 1;
                transform: translate(0);
            }
        }
    }
</style>