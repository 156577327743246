<template>
    <div id="cookie-policy-page">
        <DefaultLayout>
            <template slot="title">
                Cookie Policy
            </template>
            <div id="cookie-policy-page__content">
                <section>
                    <h2>
                        What are cookies?
                    </h2>
                    <p>
                        A cookie is a tiny text file placed on your computer, tablet or mobile by the website that you visit. Don't worry, they're not bad. Cookies do lots of useful jobs. Primarily they help make websites work smoothly, provide information about how people browse the website so we can keep improving your viewing experience, and on some websites, making sure any adverts you see are as relevant as possible.
                    </p>
                </section>
                <section>
                    <h2>
                        What we use cookies for
                    </h2>
                    <p>
                        Like most websites, onlinesolutionsltd.com uses cookies, however we don't do adverts. So these cookies are safe, secure and don’t store your personal information or share it with anyone. We simply use them for:
                    </p>
                    <ul>
                        <li>Analysing how you use onlinesolutionsltd.com</li>
                        <li>Providing you with a slicker, more personalised experience</li>
                        <li>Understanding who visits our websites and what you enjoy reading the most</li>
                        <li>Making everything just that little bit better</li>
                    </ul>
                </section>
                <section>
                    <h2>
                        How do I change my cookie settings?
                    </h2>
                    <p>
                        Our websites use three kinds of cookies. Some are strictly necessary for making our website work. But you can choose to turn all the others on or off below. You can also manage what cookies are stored on your computer directly by setting your browser to accept or reject certain cookies. Blocking some types of cookies may mean you can’t use all the services or features on our sites however, so if you want the full experience, then simply use the settings below to tell us what you're happy with.
                    </p>
                    <p>
                        Third-party cookies are set by someone other than the owner of the website you’re visiting. Some of our pages may also contain content from other sites like YouTube, which may set their own cookies. If you share a link to a page on one of our sites, the platform you share it on (for example, Facebook) may set a cookie on your browser. We have no control over third-party cookies – you can turn them off, but not through us.
                    </p>
                    <p>
                        Remember that editing your cookie preferences will not remove any existing cookies from your browser. It’ll only affect the way cookies are used in future.
                    </p>
                </section>
                <section>
                    <h2>
                        Essential cookies
                    </h2>
                    <p>
                        These are cookies that are required to run the website and make everything work how it's supposed to.
                    </p>
                    <table>
                        <thead>
                        <tr>
                            <th>Cookie name</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Cloudflare __cfduid</td>
                            <td>The _cfduid cookie helps Cloudflare detect malicious visitors to our Customers’ websites and minimizes blocking legitimate users. It may be placed on the devices of our customers' End Users to identify individual clients behind a shared IP address and apply security settings on a per-client basis. It is necessary for supporting Cloudflare's security features.</td>
                        </tr>
                        <tr>
                            <td>cookieconsent_preference</td>
                            <td>This cookie is used to remember a user’s choice about cookies on onlinesolutionsltd.com. Where users have previously indicated a preference, that user’s preference will be stored in this cookie.</td>
                        </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <h2>
                        Analytics cookies
                    </h2>
                    <p>
                        These are cookies that help us to understand how visitors use our site. We use the information to compile reports and to help us improve what we offer you as a visitor. Analytics cookies simply collect information in an anonymous form on thing like the numbers of visitors coming to our site, where you're all coming from and which pages you enjoy reading the most. We would emphasise again that none of the cookies we use contain any personal information about you and cannot be used to identify you as an individual. If you're prefer us not to collect this information, simply switch the toggle in the top right of the table below to "off" and we will no longer track your browsing activity whilst you visit our site.
                    </p>
                    <table>
                        <thead>
                        <tr>
                            <th>Cookie name</th>
                            <th>Description</th>
                            <th>
                                <ToggleSwitch :value="analyticsAccepted"
                                              :height="37"
                                              @input="toggleAnalyticsCookies"
                                />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>_gat</td>
                            <td>Used by Google Analytics to throttle request rate.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </DefaultLayout>
    </div>
</template>

<script>
    import DefaultLayout from '../layouts/DefaultLayout';
    import ToggleSwitch from "../../interactions/ToggleSwitch";

    export default {
        components: {
            ToggleSwitch,
            DefaultLayout
        },

        computed: {
            analyticsAccepted() {
                return this.$store.getters['consent/analytics'];
            }
        },

        methods: {
            toggleAnalyticsCookies(val) {
                val ? this.$store.commit('consent/acceptAnalytics') : this.$store.commit('consent/declineAnalytics');
            }
        }
    }
</script>

<style lang="scss">
    #cookie-policy-page {
    }
</style>