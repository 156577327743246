<template>
    <div class="primary-hero-slide" ref="outerContainer">
        <baseBox padding="large py-0">
            <BContainerInset class="primary-hero-slide__container"
                             ref="innerContainer"
            >
                <BaseBox class="primary-hero-slide__text"
                         text-colour="white">
                    <h1 class="primary-hero-slide__text__title"
                        v-html="title"
                    ></h1>
                    <p class="primary-hero-slide__text__description"
                       v-html="description"
                    ></p>
                    <BaseBtn v-if="btn"
                             class="primary-hero-slide__text__btn"
                             @click="routeTo"
                    >
                        {{ btn.text }}
                    </BaseBtn>
                </BaseBox>
            </BContainerInset>
        </baseBox>
        <ContentClipper ref="contentClipper"
                        class="primary-hero-slide__asset"
        >
            <component :is="assetElement"
                       v-bind="assetAttributes"
            ></component>
        </ContentClipper>
    </div>
</template>

<script>
    import ContentClipper from '../views/partials/ContentClipper';

    export default {
        components: {
            ContentClipper
        },

        props: {
            asset: Object,
            title: String,
            description: String,
            btn: Object
        },

        computed: {
            assetElement() {
                switch (this.asset.type) {
                    case 'video':
                        return 'BaseVideo';

                    default:
                        return 'BaseImg';
                }
            },

            assetAttributes() {
                switch (this.asset.type) {
                    case 'video':
                        return {
                            src: this.asset.src,
                            autoplay: true,
                            loop: true
                        };

                    default:
                        return {
                            src: this.asset.src
                        };
                }
            }
        },

        methods: {
            routeTo(){
                if(this.btn && this.btn.route){
                    this.$router.push({ name : this.btn.route })
                }
            }
        },

        mounted() {
            this.$refs.contentClipper.setAnchorContainer(this.$refs.innerContainer.$el);
            this.$refs.contentClipper.setHeightContainer(this.$refs.outerContainer);
        }
    }
</script>

<style lang="scss">
    .primary-hero-slide {
        min-height: 100vh;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: map-get($palette, "purple");
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        h1 {
            color: map-get($palette, "yellow");
        }

        &__container {
            height: 100%;
            position: relative;

            .row {
                height: 100%;
            }
        }

        &__text {
            margin: 110px 0 50px 7px;

            @include media-breakpoint-up(md) {
                margin-top: 150px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 360px;
                margin-top: 250px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 410px;
            }

            @include media-breakpoint-up(xxl) {
                margin-top: 330px;
                margin-left: 12px;
            }

            &__title, &__description, &__btn {
                transform: translateX(-60px);
                opacity: 0;
            }

            &__title {
                animation: reveal-hero-text 400ms 1200ms forwards;
            }

            &__description {
                animation: reveal-hero-text 400ms 1300ms forwards;
                width: 200px;

                @include media-breakpoint-up(md) {
                    width: 365px;
                }

                @include media-breakpoint-up(lg) {
                    width: auto;
                }
            }

            &__btn {
                animation: reveal-hero-text 400ms 1400ms forwards;
                margin-top: 40px;
            }
        }

        &__asset {
            position: static;
            background-color: map-get($palette, "yellow");
            margin-top: -150px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                margin-top: -450px;
            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 115px;
                margin-top: 0;
            }

            @include media-breakpoint-up(xl) {
                top: 0;
            }

            .base-img, .base-video {
                object-fit: cover;
                flex-grow: 1;
            }
        }
    }

    @keyframes reveal-hero-text {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>