<template>
    <BaseIcon v-bind="passthrough"
              @click="$emit('click')"
    >
        <component :is="resolvedName"></component>
    </BaseIcon>
</template>

<script>
    export default {
        props: {
            name: String,
            colour: {
                type: String,
                default: 'white'
            },
            direction: String
        },

        computed: {
            passthrough() {
                return {
                    colour: this.colour,
                    direction: this.direction
                }
            },

            resolvedName() {
                return `Icon${this.name.charAt(0).toUpperCase()}${this.name.slice(1)}`;
            }
        }
    }
</script>

<style>

</style>