<template>
    <BaseBox id="mobile-nav-trigger"
             :text-colour="colour"
             :class="classes"
             @click="click"
    >
        Menu
        <div id="mobile-nav-trigger__bars">
            <BaseBox :background-colour="colour"
                     class="mobile-nav-trigger__bars__bar mobile-nav-trigger__bars__bar--long"
            ></BaseBox>
            <BaseBox :background-colour="colour"
                     @animationend="animationFinished"
                     class="mobile-nav-trigger__bars__bar mobile-nav-trigger__bars__bar--short"
            ></BaseBox>
        </div>
    </BaseBox>
</template>

<script>
    export default {
        props: {
            colour: String
        },

        data() {
            return {
                animate: false
            }
        },

        computed: {
            classes() {
                return this.animate ? ['mobile-nav-trigger--animate'] : [];
            }
        },

        methods: {
            click() {
                this.animate = true;

                this.$emit('click')
            },

            animationFinished() {
                this.animate = false;
            }
        }
    }
</script>

<style lang="scss">
    #mobile-nav-trigger {
        overflow: hidden;
        opacity: 0;
        transform: translateX(30px);
        animation: reveal-logo 400ms 1500ms forwards;
        @include font-rhythm(13);

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &.mobile-nav-trigger--animate {
            #mobile-nav-trigger__bars {
                .mobile-nav-trigger__bars__bar {
                    animation: shimmy 530ms;

                    &--short {
                        animation-delay: 100ms;
                    }
                }
            }
        }

        &__bars {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 2px;

            .mobile-nav-trigger__bars__bar {
                height: 2px;
                transition: background-color 1000ms;
                margin-bottom: 4px;

                &--long {
                    width: 30px;
                }

                &--short {
                    width: 22px;
                }
            }
        }
    }

    @keyframes shimmy {
        50% {
            transform: translateX(50%);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes reveal-mobile-nav {
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
</style>