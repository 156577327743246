<template>
    <component class="base-link"
               :is="element"
               v-bind="attributes"
    >
        <slot></slot>
    </component>
</template>

<script>
    export default {
        props: {
            colour: {
                type: String,
                default: 'white'
            },
            activeColour: {
                type: String,
                default: 'yellow'
            },
            underlined: {
                type: Boolean,
                default: false
            },
            route: String,
            href: String,
            target: {
                type: String,
                default: '_self'
            },
            params: {
                type: Object,
                default: () => {
                    return {};
                }
            }
        },

        computed: {
            element() {
                return this.route ? 'RouterLink' : 'a';
            },

            attributes() {
                let attributes = {
                    class: this.classes
                };

                if (this.element === 'a') {
                    attributes.href = this.href;

                    if (this.target !== '_self') {
                        attributes.target = this.target;
                    }
                } else {
                    attributes.to = {
                        name : this.route,
                        params: this.params
                    };
                }

                return attributes;
            },

            classes() {
                let classes = [
                    `base-link--${this.colour}`,
                    `base-link--active-${this.activeColour}`
                ];

                if (this.underlined) {
                    classes.push('base-link--underlined')
                }

                return classes;
            }
        }
    }
</script>

<style lang="scss">
    .base-link {
        cursor: pointer;
        transition: color 1000ms;

        @each $name, $hex in $palette {
            &--#{$name} {
                color: $hex !important;
            }

            &--active-#{$name} {
                &.router-link-exact-active {
                    color: $hex !important;
                }
            }
        }

        &--underlined {
            display: block;
            width: fit-content;
            overflow: hidden;
            text-decoration: none !important;

            &:after {
                content: '';
                display: block;
                border-bottom: solid 2px;
                transform: translateX(-101%);
                transition: transform 400ms, border-bottom-color 1000ms;
                backface-visibility: hidden;
            }

            &.router-link-exact-active, &:hover {
                &:after {
                    transform: translateX(0);
                }
            }

            @each $name, $hex in $palette {
                &.base-link--active-#{$name} {
                    &:after {
                        border-bottom-color: $hex;
                    }
                }
            }
        }
    }
</style>