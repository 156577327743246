<template>
    <div id="work-page">
        <BaseBox background-colour="black">
            <DefaultLayout>
                <template slot="title">
                    Take a look<br>at our work
                </template>
                <template slot="summary">
                    <p>Check out some of the unique digital solutions the OS team have designed and developed to help our clients achieve their goals.</p>
                </template>
                <CatalogueContainer id="work-page__filters">
                    <CatalogueColumn>
                        <BFormSelect @change="select('sector', $event)"
                                     :value="categories.sector"
                                     :options="sectorOptions"
                                     class="d-lg-none"
                        />
                        <BaseDropdown @input="select('sector', $event)"
                                      :value="categories.sector"
                                      :options="sectorOptions"
                                      class="d-none d-lg-block"
                        />
                    </CatalogueColumn>
                    <CatalogueColumn>
                        <BFormSelect @change="select('service', $event)"
                                     :value="categories.service"
                                     :options="serviceOptions"
                                     class="d-lg-none"
                        />
                        <BaseDropdown @input="select('service', $event)"
                                      :value="categories.service"
                                      :options="serviceOptions"
                                      class="d-none d-lg-block"
                        />
                    </CatalogueColumn>
                    <CatalogueColumn>
                        <BFormSelect @change="select('solution', $event)"
                                     :value="categories.solution"
                                     :options="solutionOptions"
                                     class="d-lg-none"
                        />
                        <BaseDropdown @input="select('solution', $event)"
                                      :value="categories.solution"
                                      :options="solutionOptions"
                                      class="d-none d-lg-block"
                        />
                    </CatalogueColumn>
                </CatalogueContainer>
                <transition-group id="work-page__catalogue"
                                  name="work-page__catalogue"
                                  tag="div"
                                  class="catalogue-container"
                >
                    <CatalogueColumn v-for="caseStudy in filteredCaseStudies"
                                     :key="caseStudy.lookupKey"
                                     class="work-page__catalogue__item"
                                     :class="[`work-page__catalogue__item--${caseStudy.lookupKey}`]"
                    >
                        <CaseStudyPoster v-bind="caseStudy.poster"
                                         :slug="caseStudy.lookupKey"
                        />
                    </CatalogueColumn>
                </transition-group>
            </DefaultLayout>
        </BaseBox>
    </div>
</template>

<script>
    import data from '../../../data';
    import DefaultLayout from '../layouts/DefaultLayout';
    import CaseStudyPoster from '../../posters/CaseStudyPoster';

    export default {
        components: {
            DefaultLayout,
            CaseStudyPoster
        },

        data() {
            return Object.assign(
                {
                    categories: {
                        sector: null,
                        service: null,
                        solution: null
                    }
                },
                data.work,
                {
                    tags : data.tags,
                    caseStudies : data.caseStudies
                }
            );
        },

        computed: {
            sectorOptions() {
                return [{
                    text: 'Sector',
                    value: null
                }].concat(this.tags.sectors.map(sector => {
                    return {
                        text: sector,
                        value: sector
                    }
                }));
            },

            serviceOptions() {
                return [{
                    text: 'Service',
                    value: null
                }].concat(this.tags.services.map(service => {
                    return {
                        text: service,
                        value: service
                    }
                }));
            },

            solutionOptions() {
                return [{
                    text: 'Solution',
                    value: null
                }].concat(this.tags.solution.map(solution => {
                    return {
                        text: solution,
                        value: solution
                    }
                }));
            },

            filteredCaseStudies() {
                return this.caseStudies.filter(caseStudy => {
                    if (this.categories.sector && caseStudy.tags.sectors.indexOf(this.categories.sector) === -1) {
                        return false;
                    }

                    if (this.categories.service && caseStudy.tags.services.indexOf(this.categories.service) === -1) {
                        return false;
                    }

                    if (this.categories.solution && caseStudy.tags.solution.indexOf(this.categories.solution) === -1) {
                        return false;
                    }

                    return true;
                });
            }
        },

        methods: {
            select(key, value) {
                for (let category in this.categories) {
                    this.categories[category] = null;
                }

                this.categories[key] = value;
            }
        }
    }
</script>

<style lang="scss">
    #work-page {
        overflow: hidden;

        &__filters {
            margin-bottom: 40px;
        }

        &__catalogue {
            z-index: 1;

            @include media-breakpoint-up(lg) {
                margin-bottom: 160px;
            }
        }

        .custom-select {
            width: 100%;
        }

        .base-dropdown {
            .dropdown-menu {
                > li {
                    &:not(:first-child) {
                        .b-dropdown-text {
                            padding-left: 30px;
                        }
                    }
                }
            }
        }

        .case-study-poster {
            margin-bottom: 40px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 70px;
            }
        }

        .work-page__catalogue__item {
            transition: transform 400ms;

            &--alton-green {
                .case-study-poster__assets__logo {
                    width: 48%;
                }
            }

            &--art-tickets {
                .case-study-poster__assets__logo {
                    width: 65%;
                }
            }

            &--lloyds-register {
                .case-study-poster__assets__logo {
                    width: 28%;
                }
            }

            &--henry-moore {
                .case-study-poster__assets__logo {
                    width: 35%;
                }
            }

            &--waverton {
                .case-study-poster__assets__logo {
                    width: 70%;
                }
            }

            &--student-art-pass {
                .case-study-poster__assets__logo {
                    width: 75%;
                }
            }
        }

        .work-page__catalogue-enter, .work-page__catalogue-leave-to {
            pointer-events: none;
            opacity: 0;
        }

        .work-page__catalogue-leave-active {
            position: absolute;
        }

        .work-page__catalogue-move {
            pointer-events: none;
        }
    }
</style>