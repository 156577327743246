<template>
    <component :is="element"
               class="base-box"
               :class="classes"
               @click="emit('click')"
               @animationend="emit('animationend')"
               v-bind="bindings"
    >
        <slot></slot>
    </component>
</template>

<script>
    export default {
        props: {
            textColour: String,
            backgroundColour: String,
            borderColour: String,
            rollover: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            padding: [String, Object],
            element: {
                type: String,
                default: 'div'
            },
            bindings: {
                type: Object,
                default: () => {
                    return {};
                }
            }
        },

        computed: {
            classes() {
                let classes = [];

                if (this.backgroundColour) {
                    classes.push(`base-box--background-${this.backgroundColour}`)
                }

                if (this.textColour) {
                    classes.push(`base-box--text-${this.textColour}`);
                }

                if (this.borderColour) {
                    classes.push(`base-box--border-${this.borderColour}`);
                }

                if (this.rollover.backgroundColour) {
                    classes.push(`base-box--rollover-background-${this.rollover.backgroundColour}`)
                }

                if (this.rollover.textColour) {
                    classes.push(`base-box--rollover-text-${this.rollover.textColour}`);
                }

                if (this.rollover.borderColour) {
                    classes.push(`base-box--rollover-border-${this.rollover.borderColour}`);
                }

                if (this.padding) {
                    classes.push(`base-box--padding-${this.padding}`);
                }

                return classes;
            }
        },

        methods: {
            emit(event) {
                this.$emit(event);
            }
        }
    }
</script>

<style lang="scss">
    .base-box {
        transition: background-color 400ms, color 400ms, border-color 400ms;

        @each $name, $hex in $palette {
            &--background-#{$name} {
                background-color: $hex;
            }

            &--text-#{$name} {
                color: $hex;
            }

            &--border-#{$name} {
                border: solid 1px $hex;
            }

            &--rollover-background-#{$name} {
                &:hover {
                    background-color: $hex;
                }
            }

            &--rollover-text-#{$name} {
                &:hover {
                    color: $hex;
                }
            }

            &--rollover-border-#{$name} {
                &:hover {
                    border-color: $hex;
                }
            }
        }

        &--padding {
            &-small {
                padding: map_get($spacers, 5);
            }

            &-medium {
                padding: map_get($spacers, 6);
            }

            &-large {
                padding: map_get($spacers, 7);
            }
        }

        & > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>