import HomePage from './components/views/templates/HomePage';
import CaseStudyPage from './components/views/templates/CaseStudyPage';
import WorkPage from './components/views/templates/WorkPage';
import CookiePolicyPage from './components/views/templates/CookiePolicyPage';
import CareersPage from './components/views/templates/CareersPage';
import ContactPage from './components/views/templates/ContactPage';
import PrivacyPage from './components/views/templates/PrivacyPage';
import SustainabilityPage from './components/views/templates/SustainabilityPage';

const routes = [
    {
        name: 'home',
        path: '/',
        component: HomePage
    },
    {
        name: 'work',
        path: '/work',
        component: WorkPage
    },
    {
        name: 'case-study',
        path: '/work/:slug',
        component: CaseStudyPage,
        props: true
    },
    {
        name: 'cookie-policy',
        path: '/cookie-policy',
        component: CookiePolicyPage,
    },
    {
        name: 'careers',
        path: '/careers',
        component: CareersPage,
    },
    {
        name: 'contact',
        path: '/contact',
        component: ContactPage,
    },
    {
        name: 'privacy',
        path: '/privacy',
        component: PrivacyPage
    },
    {
        name: 'sustainability',
        path: '/sustainability',
        component: SustainabilityPage
    }
];

export default routes;
