<template>
    <div class="copyright-stub">
        © {{ year }} Online Solutions UK Ltd.
        <br>
        Registered in England No. 4854663.
    </div>
</template>

<script>
    export default {
        data() {
            return {
                year: new Date().getFullYear()
            }
        }
    }
</script>

<style lang="scss">
     .copyright-stub {
        margin-top: 25px;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }
</style>