<template>
    <PageSlide background-colour="black"
               text-colour="white"
               class="work-slide"
    >
        <BContainerInset ref="container">
            <BRow>
                <BCol>
                    <h4 class="work-slide__title h1">
                        See more<br>of our work
                    </h4>
                </BCol>
            </BRow>
            <CatalogueContainer class="work-slide__items">
                <CatalogueColumn v-for="(item, index) in resolvedItems"
                                 :key="index"
                                 class="work-slide__items__item"
                >
                    <CaseStudyPoster :slug="item.lookupKey"
                                     v-bind="item.poster"
                    />
                </CatalogueColumn>
            </CatalogueContainer>
        </BContainerInset>
        <FooterStripeTip ref="stripeTip"/>
    </PageSlide>
</template>

<script>
    import data from '../../data';
    import PageSlide from './PageSlide';
    import CaseStudyPoster from '../posters/CaseStudyPoster';
    import FooterStripeTip from '../views/partials/stubs/FooterStripeTip';

    export default {
        components: {
            PageSlide,
            CaseStudyPoster,
            FooterStripeTip
        },

        props: {
            items: Array
        },

        computed: {
            resolvedItems() {
                return this.items.map((item) => {
                    let match = data.caseStudies.find(caseStudy => {
                        return caseStudy.lookupKey === item;
                    });

                    if (!match) {
                        console.log(`Missing item ${item}`);

                        return {};
                    }

                    return match;
                });
            }
        },

        mounted() {
            this.$refs.stripeTip.setAnchorContainer(this.$refs.container.$el);
        }
    }
</script>

<style lang="scss">
    .work-slide {
        padding: 50px 40px 0 40px;

        @include media-breakpoint-up(lg) {
            padding-top: 180px;
        }

        &__title {
            opacity: 0;
            transform: translateX(-200px);
            transition: opacity 400ms, transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &__items {
            &__item {
                opacity: 0;
                transform: translateY(100px);
                transition: opacity 400ms, transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
                margin-bottom: 40px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }

                &:nth-child(2) {
                    transition-delay: 100ms;
                }

                &:last-child {
                    transition-delay: 200ms;
                }
            }
        }

        .h1 {
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 65px;
            }
        }

        &.page-slide--in {
            .work-slide__title, .work-slide__items__item {
                opacity: 1;
                transform: translate(0);
            }
        }
    }
</style>