<template>
    <BaseBox :background-colour="colour"
             :style="styles"
             class="stripe-tip"
    >
    </BaseBox>
</template>

<script>
    export default {
        props: {
            colour: {
                type: String,
                default: 'purple'
            }
        },

        data() {
            return {
                windowWidth: null,
                height: null,
                anchorContainer: null,
                anchorWidth: null
            }
        },

        computed: {
            styles() {
                if (this.windowWidth > 1399) {
                    let width = (this.windowWidth - this.anchorWidth) / 2;

                    return {
                        width: `${width}px`,
                        height: `${width}px`
                    };
                } else {
                    return {
                        display: 'none'
                    }
                }
            },
        },
        
        methods: {
            setAnchorContainer(container) {
                this.anchorContainer = container;
                this.anchorWidth = container.offsetWidth;

                return this;
            },
        },

        mounted() {
            this.windowWidth = document.body.clientWidth;

            window.addEventListener('resize', () => {
                this.windowWidth = document.body.clientWidth;
                this.anchorWidth = this.anchorContainer.offsetWidth;
            });
        }
    }
</script>

<style lang="scss">
    .stripe-tip {
        position: absolute;
        right: 0;
        bottom: 0;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        z-index: 1;
    }
</style>