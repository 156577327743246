<template>
    <div class="radial-progress"
         :class="classes"
         ref="container"
    >
        <div ref="bar"
             class="radial-progress__bar"
        ></div>
        <slot></slot>
    </div>
</template>

<script>
    import ProgressBar from 'progressbar.js';

    export default {
        props: {
            value: Number,
            play: Boolean
        },

        data() {
            return {
                bar: null,
            }
        },

        watch: {
            play(val) {
                if (val) {
                    window.setTimeout(() => {
                        this.bar.animate(this.value / 100);
                    }, 500);
                }
            }
        },

        computed: {
            classes() {
                return this.play ? ['radial-progress--started'] : [];
            }
        },

        mounted() {
            this.bar = new ProgressBar.Circle(this.$refs.bar, {
                color: '#FEEA47',
                trailColor: '#6D35EE',
                strokeWidth: 6,
                trailWidth: 6,
                easing: 'bounce',
                duration: 1700,
                text: {
                    value: `${this.value}%`,
                    style: {
                        color: '#FFFFFF',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        padding: 0,
                        margin: 0,
                        transform: {
                            prefix: true,
                            value: 'translate(-50%, -50%)'
                        }
                    }
                }
            });
        }
    }
</script>

<style lang="scss">
    .radial-progress {
        text-align: center;
        width: 105px;
        margin-bottom: 30px;

        @include media-breakpoint-up(sm) {
            width: 28%;
            max-width: 235px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--started {
            .radial-progress__bar {
                .progressbar-text {
                    opacity: 1;
                }
            }
        }

        &__bar {
            width: 90px;
            margin: 0 auto 15px auto;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 45px;
            }

            .progressbar-text {
                opacity: 0;
                transition: opacity 2000ms 1000ms;
                @include font-size(26);

                @include media-breakpoint-up(sm) {
                    @include font-size(40);
                }

                @include media-breakpoint-up(md) {
                    @include font-size(70);
                }

                @include media-breakpoint-up(lg) {
                    @include font-size(80);
                }
            }
        }
    }
</style>