<template>
    <div class="quote-stub">
        <BaseBox text-colour="yellow"
                 class="quote-stub__text"
        >
            <div class="quote-stub__text__punctuation quote-stub__text__punctuation--open">
                <IconRenderer name="quoteMark"
                              colour="yellow"
                />
            </div>
            <div v-html="content.text"
                 class="quote-stub__text__comment"
            ></div>
            <div class="quote-stub__text__punctuation quote-stub__text__punctuation--close">
                <IconRenderer name="quoteMark"
                              colour="yellow"
                />
            </div>
        </BaseBox>
        <BaseBox v-if="content.author"
                 text-colour="yellow"
                 class="quote-stub__author"
        >
            {{ content.author }}
        </BaseBox>
        <BaseBox text-colour="yellow"
                 class="quote-stub__job-title"
        >
            {{ content.jobTitle }}
        </BaseBox>
    </div>
</template>

<script>
    export default {
        props: {
            content: Object
        }
    }
</script>

<style lang="scss">
    .quote-stub {
        margin: 30px 0;
        max-width: 960px;

        @include media-breakpoint-up(lg) {
            margin: 60px 0;
        }

        &__text {
            width: fit-content;

            &__comment {
                @include font-rhythm(17);
                margin-right: 60px;
                transition-delay: 200ms;

                @include media-breakpoint-up(md) {
                    @include font-rhythm(32);
                }

                @include media-breakpoint-up(lg) {
                    margin-right: 140px;
                    @include font-rhythm(40);
                }
            }

            &__punctuation {
                width: 30px;
                opacity: 0;
                transition: opacity 400ms, transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-delay: 300ms;

                @include media-breakpoint-up(lg) {
                    width: 70px;
                }

                &--open {
                    margin-bottom: 20px;
                    transform: translateX(-100px);

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 40px;
                    }
                }

                &--close {
                    transform: translateX(100px);
                    margin-left: auto;

                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &__author {
            transition-delay: 300ms;
        }

        &__job-title {
            transition-delay: 350ms;
        }
    }
</style>