<template>
    <b-dropdown :text="text"
                ref="dropdown"
                class="base-dropdown"
    >
        <b-dropdown-text v-for="(option, index) in options"
                         :key="index"
                         @click="select(option)"
        >
            {{ option.text }}
        </b-dropdown-text>
    </b-dropdown>
</template>

<script>
    export default {
        props: {
            value: null,
            options: Array
        },

        computed: {
            text() {
                if (this.options.length) {
                    let match = this.options.find(option => {
                        return option.value === this.value;
                    });

                    return match ? match.text : this.options[0].text;
                }

                return '';
            }
        },

        methods: {
            select(option) {
                this.$emit('input', option.value);

                this.$refs.dropdown.hide(true);
            }
        }
    }
</script>

<style lang="scss">
    .base-dropdown {
        display: block;

        .dropdown-toggle {
            display: block;
            outline: none !important;
            border: none;
            border-bottom: solid 2px map-get($palette, "white");
            background: transparent url("../../assets/icons/chevron-down.svg") center right no-repeat;
            background-size: 21px 10px;
            width: 100%;
            color: inherit;
            text-align: left;
            padding: 0 0 6px 0;

            &:after {
                display: none;
            }
        }

        .dropdown-menu {
            width: 100%;
            padding: 15px 0;
            border: none;
            top: -2px !important;
        }

        .b-dropdown-text {
            cursor: pointer;
            padding: 8px 15px;
            @include font-rhythm(20);

            &:hover {
                background-color: map-get($palette, "yellow");
            }
        }
    }
</style>