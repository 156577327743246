<template>
    <div id="master-layout">
        <ThePageContent>
            <slot></slot>
        </ThePageContent>
        <div id="master-layout__revealer"
             :class="revealerClasses"
        >
            <div id="master-layout__revealer__sizer"
                 :style="revealerSizerStyles"
            >
                <div id="master-layout__revealer__animator"></div>
            </div>
        </div>
        <CookieConsent/>
        <!--<LandscapeBlocker/>-->
    </div>
</template>

<script>
    import ThePageContent from '../partials/ThePageContent';
    import CookieConsent from '../../interactions/CookieConsent';
    // import LandscapeBlocker from '../../animation/LandscapeBlocker';

    export default {
        components: {
            ThePageContent,
            CookieConsent,
            // LandscapeBlocker
        },

        data() {
            return {
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
            }
        },

        computed: {
            revealerClasses() {
                let classes = [];

                if (this.$store.getters['revealer/in']) {
                    classes.push('master-layout__revealer--in')
                }

                if (this.$store.getters['revealer/in'] === false) {
                    classes.push('master-layout__revealer--out')
                }

                return classes;
            },

            revealerSizerStyles() {
                let buffer = 100;
                let diagonal = Math.sqrt(Math.pow(this.windowWidth, 2) + Math.pow(this.windowHeight, 2)) + buffer;

                return {
                    width: `${diagonal}px`,
                    height: `${diagonal}px`,
                    transform: `translate3d(-50%,-50%,0) rotate3d(0,0,1,135deg) translate3d(0, ${diagonal}px, 0)`
                };
            }
        },

        mounted() {
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
            });
        }
    }
</script>

<style lang="scss">
    #master-layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow-x: hidden;
        position: relative;

        &__revealer {
            position: fixed;
            z-index: 2;
            pointer-events: none;
            width: 100%;
            height: 100vh;
            overflow: hidden;

            &.master-layout__revealer--in {
                #master-layout__revealer__animator {
                    animation: revealer-in 1000ms forwards;
                }
            }

            &.master-layout__revealer--out {
                #master-layout__revealer__animator {
                    animation: revealer-out 1000ms forwards;
                }
            }

            &__sizer {
                position: absolute;
                top: 50%;
                left: 50%;
            }

            &__animator {
                width: 100%;
                height: 100%;
                background: map-get($palette, "purple");
            }
        }
    }

    @keyframes revealer-in {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -100%, 0);
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
        }
    }

    @keyframes revealer-out {
        0% {
            transform: translate3d(0, -100%, 0);
        }
        100% {
            transform: translate3d(0, -200%, 0);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        }
    }
</style>