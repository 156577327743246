<template>
    <BaseBox class="base-btn"
             v-bind="passthrough"
             @click="click"
    >
        <slot></slot>
    </BaseBox>
</template>

<script>
    export default {
        props: {
            textColour: {
                type: String,
                default: 'white'
            },
            borderColour: {
                type: String,
                default: 'yellow'
            },
            to: Object
        },

        computed: {
            passthrough() {
                let attributes = {
                    textColour: this.textColour,
                    borderColour: this.borderColour,
                    rollover: {
                        backgroundColour: this.borderColour
                    }
                };

                switch (attributes.rollover.backgroundColour) {
                    case 'purple':
                        attributes.rollover.textColour = 'white';
                        break;

                    case 'yellow':
                        attributes.rollover.textColour = 'black';
                        break;
                }

                return attributes;
            }
        },

        methods: {
            click() {
                this.$emit('click');

                if (this.to) {
                    this.$router.push(this.to);
                }
            }
        }
    }
</script>

<style lang="scss">
    .base-btn {
        cursor: pointer;
        padding: 12px 17px;
        text-transform: uppercase;
        text-align: center;
        width: fit-content;
        letter-spacing: 1.7px;
        @include font-size(11);
        @include line-height(11);

        @include media-breakpoint-up(md) {
            padding: 16px 26px;
            @include font-size(12);
            @include line-height(12);
        }

        @include media-breakpoint-up(xl) {
            padding: 18px 28px;
            @include font-size(14);
            @include line-height(14);
        }
    }
</style>