<template>
    <video muted
           :src="resolvedSrc"
           :autoplay="autoplay"
           :loop="loop"
           playsinline="playsinline"
           class="base-video"
           ref="video"
    >
        Your browser does not support the video tag.
    </video>
</template>

<script>
    export default {
        props: {
            src: null,
            autoplay: {
                type: Boolean,
                default: false
            },
            loop: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                ready: false,
                windowWidth: null
            }
        },

        computed: {
            hasMultipleSources() {
                return typeof this.src === 'object';
            },

            resolvedSrc() {
                if (!this.ready) {
                    return null;
                }

                let src = this.src;

                if (this.hasMultipleSources) {
                    let keys = Object.keys(this.src).reverse();

                    for (let i = 0; i < keys.length; i++) {
                        if (this.windowWidth >= keys[i]) {
                            src = this.src[keys[i]];
                            break;
                        }
                    }
                }

                return require(`../../assets/${src}`);
            }
        },

        methods: {
            play() {
                this.$refs.video.play();
            }
        },

        mounted() {
            if (this.hasMultipleSources) {
                this.windowWidth = window.innerWidth;

                window.addEventListener('resize', () => {
                    this.windowWidth = window.innerWidth;
                });
            }

            this.ready = true;
        }
    }
</script>

<style lang="scss">
    .base-video {
        width: 100%;
        display: block;
    }
</style>