<template>
    <InlineLinksStub :items="items"/>
</template>

<script>
    import InlineLinksStub from './InlineLinksStub';

    export default {
        components: {
            InlineLinksStub
        },

        data() {
            return {
                items: [
                    {
                        text: 'Cookies',
                        attributes: {
                            route: 'cookie-policy'
                        }
                    },
                    {
                        text: 'Your privacy',
                        attributes: {
                            route: 'privacy'
                        }
                    },
                    {
                        text: 'Sustainability',
                        attributes: {
                            route: 'sustainability'
                        }
                    }
                ],
            }
        }
    }
</script>
