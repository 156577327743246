<template>
    <img class="base-img" :src="resolvedSrc">
</template>

<script>
    export default {
        props: {
            src: String
        },

        computed: {
            resolvedSrc() {
                return require(`../../assets/${this.src}`);
            }
        }
    }
</script>

<style lang="scss">
    .base-img {
        display: block;
        width: 100%;
        height: auto;
    }
</style>