<template>
    <div class="social-stub">
        <h6 class="mb-0">Follow us</h6>
        <InlineLinksStub :items="items"/>
    </div>
</template>

<script>
    import InlineLinksStub from './InlineLinksStub';

    export default {
        components: {
            InlineLinksStub
        },

        data() {
            return {
                items: [
                    {
                        text: 'Facebook',
                        attributes: {
                            href: ' https://www.facebook.com/osolutionsltd/',
                            target: '_blank'
                        }
                    },
                    {
                        text: 'LinkedIn',
                        attributes: {
                            href: 'https://www.linkedin.com/company/online-solutions-uk-ltd./',
                            target: '_blank'
                        }
                    },
                    {
                        text: 'Instagram',
                        attributes: {
                            href: 'https://www.instagram.com/onlinesolutionsukltd/',
                            target: '_blank'
                        }
                    }
                ],
            }
        }
    }
</script>