<template>
    <BaseBox id="contact-page"
             background-colour="black"
    >
        <DefaultLayout minimal-footer>
            <template slot="title">
                How can we<br>help you?
            </template>
            <template slot="summary">
                <p>We’d love to hear about your<br>challenges and ambitions</p>
            </template>
            <BRow>
                <BCol xl="4">
                    <h2>
                        Send us a message
                    </h2>
                    <div v-if="form.complete">
                        Thanks for your enquiry.
                    </div>
                    <div v-else
                         id="contact-page__form"
                    >
                        <BaseFormField :error="form.errors.name">
                            <BaseInput v-model="form.fields.name"
                                       label="Name"
                            />
                        </BaseFormField>
                        <BaseFormField :error="form.errors.email">
                            <BaseInput v-model="form.fields.email"
                                       label="Email address"
                            />
                        </BaseFormField>
                        <BaseFormField :error="form.errors.telephone">
                            <BaseInput v-model="form.fields.telephone"
                                       label="Phone (optional)"
                            />
                        </BaseFormField>
                        <BaseFormField :error="form.errors.message">
                            <BaseInput v-model="form.fields.message"
                                       type="textarea"
                                       label="Message"
                            />
                        </BaseFormField>
                        <BaseBtn border-colour="yellow"
                                 text-colour="white"
                                 @click="send"
                        >
                            Send Message
                        </BaseBtn>
                    </div>
                </BCol>
                <BCol xl="3" offset-xl="1">
                    <EmailCtaStub/>
                    <TelephoneCtaStub/>
                    <!--<HiringCtaStub class="mb-0"/>-->
                </BCol>
            </BRow>
        </DefaultLayout>
    </BaseBox>
</template>

<script>
    import RestModel from '@oilstone/rest-model';
    import DefaultLayout from '../layouts/DefaultLayout';
    import TelephoneCtaStub from '../partials/stubs/TelephoneCtaStub';
    import EmailCtaStub from '../partials/stubs/EmailCtaStub';
    // import HiringCtaStub from '../partials/stubs/HiringCtaStub';
    import BaseBox from "../../base/BaseBox";

    export default {
        components: {
            BaseBox,
            DefaultLayout,
            TelephoneCtaStub,
            EmailCtaStub,
            // HiringCtaStub,
        },

        data() {
            return {
                form: {
                    sending: false,
                    complete: false,
                    fields: {
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                    },
                    errors: {
                        name: null,
                        email: null,
                        phone: null,
                        message: null
                    }
                }
            }
        },

        methods: {
            send() {
                let error = false;
                let required = ['name', 'email', 'message'];

                for (let error in this.form.errors) {
                    this.form.errors[error] = null;
                }

                for (let field in this.form.fields) {
                    if (!this.form.fields[field] && required.indexOf(field) !== -1) {
                        error = true;
                        this.form.errors[field] = `The ${field} field is required`;
                    }
                }

                if (!this.sending && !error) {
                    this.sending = true;

                    RestModel.resolve('messages').record(this.form.fields).$save().then(() => {
                        this.sending = false;
                        this.form.complete = true;
                    }, () => {
                        this.sending = false;
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    #contact-page {
        &__form {
            margin-bottom: 35px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }
    }
</style>