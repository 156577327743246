<template>
    <BaseBox background-colour="purple"
             :style="styles"
             class="header-stripe"
    ></BaseBox>
</template>

<script>
    export default {
        data() {
            return {
                windowWidth: null,
                anchorContainer: null,
                anchorWidth: null
            }
        },

        computed: {
            gutterWidth() {
                if (!this.anchorWidth) {
                    return 0;
                }

                return (this.windowWidth - this.anchorWidth) / 2;
            },

            styles() {
                let overlap = 130;

                if (this.windowWidth > 1199) {
                    overlap = 250;
                } else if (this.windowWidth > 991) {
                    overlap = 200;
                } else if (this.windowWidth > 767) {
                    overlap = 150;
                }

                let width = this.gutterWidth + overlap;

                return {
                    width: `${width}px`,
                    height: `${width}px`
                };
            },
        },

        methods: {
            setAnchorContainer(container) {
                this.anchorContainer = container;
                this.anchorWidth = container.offsetWidth;

                return this;
            }
        },

        mounted() {
            this.windowWidth = window.innerWidth;

            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
                this.anchorWidth = this.anchorContainer.offsetWidth;
            });
        }
    }
</script>

<style lang="scss">
    .header-stripe {
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
</style>