<template>
    <div class="catalogue-container__column">
        <slot></slot>
    </div>
</template>

<script>
    export default {
    }
</script>

<style lang="scss">
    .catalogue-container {
        &__column {
            @include media-breakpoint-up(sm) {
                width: 50%;
                padding: 0 18px;
            }

            @include media-breakpoint-up(md) {
                width: 33.333%;
                padding: 0 26px;
            }

            @include media-breakpoint-up(xxl) {
                padding: 0 35px;
            }
        }
    }
</style>