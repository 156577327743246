<template>
    <header class="page-header"
            :class="classes"
    >
        <div class="page-header__bar">
            <BaseBox padding="large"
                     :text-colour="colour"
                     class="py-0"
            >
                <BContainerInset>
                    <div class="page-header__bar__content">
                        <div class="page-header__logo">
                            <router-link :to="{ name : 'home' }">
                                <LogoAnimation :expanded="logoExpanded"
                                               :animate="animateLogo"
                                               :animate-on-rollover="animateLogoOnRollover"
                                />
                            </router-link>
                        </div>
                        <TheDesktopNav :colour="colour"
                                       :active-colour="activeColour"
                        />
                        <MobileNavTrigger :colour="colour"
                                          @click="activateMobileNav"
                        />
                    </div>
                </BContainerInset>
            </BaseBox>
        </div>
        <TheMobileNav :active="mobileNavActive"
                      @close="deactivateMobileNav"
        />
    </header>
</template>

<script>
    import TheDesktopNav from './TheDesktopNav';
    import TheMobileNav from './TheMobileNav';
    import MobileNavTrigger from '../../interactions/MobileNavTrigger';
    import LogoAnimation from '../../animation/LogoAnimation';

    export default {
        components: {
            TheDesktopNav,
            TheMobileNav,
            MobileNavTrigger,
            LogoAnimation
        },

        props: {
            colour: {
                type: String,
                default: 'white'
            },
            activeColour: {
                type: String,
                default: 'purple'
            },
            animateLogo: {
                type: Boolean,
                default: false
            },
            animateLogoOnRollover: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                ready: false,
                mobileNavActive: false,
                logoExpanded: this.animateLogo,
            }
        },

        methods: {
            activateMobileNav() {
                this.mobileNavActive = true;
            },

            deactivateMobileNav() {
                this.mobileNavActive = false;
            },
        },

        computed: {
            classes() {
                let classes = [];

                if (this.ready) {
                    classes.push('page-header--ready');
                }

                return classes;
            }
        },

        mounted() {
            if (this.animateLogo) {
                window.setTimeout(() => {
                    this.logoExpanded = false;
                }, 3500);
            }
        }
    }
</script>

<style lang="scss">
    .page-header {
        position: absolute;
        z-index: 2;
        width: 100%;

        &__bar {
            &__content {
                padding: 20px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include media-breakpoint-up(md) {
                    padding: 30px 0;
                }

                @include media-breakpoint-up(lg) {
                    padding: 40px 0;
                }

                @include media-breakpoint-up(xl) {
                    padding: 65px 0;
                }
            }
        }

        &__logo {
            transform: translateX(-30px);
            opacity: 0;
            animation: reveal-logo 400ms 1500ms forwards;
        }

        @keyframes reveal-logo {
            100% {
                transform: none;
                opacity: 1;
            }
        }
    }
</style>