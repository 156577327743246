import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vuex);

Vue.use(BootstrapVue, {
    breakpoints: [`xs`, 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']
});

// Register all components within the components/base dir globally
const requireComponent = require.context(
    // Look for files in the components/base directory
    '../components/base',
    // Look in subdirectories
    true,
    // Only include .vue files
    /\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((filename) => {
    // Get the component config
    const componentConfig = requireComponent(filename);
    const componentName = filename.split('/').pop().replace(/\.\w+$/, '');

    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig)
});

export default Vue;