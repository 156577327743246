<template>
    <PageSlide v-bind="slidePassthrough"
               class="details-slide"
               @in="scrollIn"
    >
        <BContainerInset>
            <BRow>
                <BCol lg="4">
                    <h2 v-html="title"
                        class="details-slide__title h1"
                    ></h2>
                    <div v-html="lede"
                         class="details-slide__lede"
                    ></div>
                </BCol>
            </BRow>
            <BRow>
                <BCol>
                    <template v-for="(block, blockIndex) in blocks">
                        <BaseTextbox v-if="block.type === 'text'"
                                     v-html="block.attributes.content"
                                     :key="blockIndex"
                                     class="details-slide__text"
                        ></BaseTextbox>
                        <QuoteStub v-if="block.type === 'quote'"
                                   :key="blockIndex"
                                   :content="block.attributes"
                        />
                        <div v-if="block.type === 'stats'"
                             :key="blockIndex"
                             class="details-slide__stats"
                             :class="[`details-slide__stats--${block.attributes.type}`]"
                        >
                            <template v-for="(stat, statIndex) in block.attributes.items">
                                <MovementStatStub v-if="block.attributes.type === 'movement'"
                                                  :key="statIndex"
                                                  :value="stat.value"
                                                  :direction="stat.direction"
                                >
                                    <div v-html="stat.title"></div>
                                </MovementStatStub>
                                <RadialProgress v-else
                                                :key="statIndex"
                                                :value="stat.value"
                                                :play="playStatsAnimation"
                                >
                                    {{ stat.title }}
                                </RadialProgress>
                            </template>
                        </div>
                    </template>
                </BCol>
            </BRow>
        </BContainerInset>
    </PageSlide>
</template>

<script>
    import PageSlide from './PageSlide';
    import RadialProgress from '../animation/RadialProgress';
    import MovementStatStub from '../views/partials/stubs/MovementStatStub'
    import QuoteStub from '../views/partials/stubs/QuoteStub';

    export default {
        components: {
            PageSlide,
            RadialProgress,
            MovementStatStub,
            QuoteStub
        },

        props: {
            backgroundColour: String,
            textColour: String,
            title: String,
            lede: String,
            blocks: Array
        },

        data() {
            return {
                playStatsAnimation: false
            }
        },

        computed: {
            slidePassthrough() {
                return {
                    backgroundColour: this.backgroundColour,
                    textColour: this.textColour
                }
            },
        },

        methods: {
            scrollIn() {
                this.playStatsAnimation = true;
            }
        }
    }
</script>

<style lang="scss">
    .details-slide {
        padding: 50px;

        @include media-breakpoint-up(lg) {
            padding: 100px;
        }

        &__title, &__lede {
            opacity: 0;
            transform: translateX(-200px);
            transition: opacity 400ms, transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &__lede {
            transition-delay: 100ms;
            margin-bottom: 30px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 60px;
            }

            & > * {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .quote-stub__text__comment, .quote-stub__author, .quote-stub__job-title, &__stats, &__text {
            opacity: 0;
            transform: translateY(100px);
            transition: opacity 400ms, transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &__stats {
            display: flex;
            flex-wrap: wrap;
            transition-delay: 500ms;
            margin: 30px 0;
            justify-content: space-between;

            &:first-child {
                margin-top: 20px;
            }

            @include media-breakpoint-up(lg) {
                margin: 60px 0;
            }

            &--radial {
                justify-content: space-around;

                @include media-breakpoint-up(sm) {
                    justify-content: space-between;
                }
            }

            &--movement {
                align-items: flex-end;
                margin-bottom: 10px;
                margin-left: -10px;
                margin-right: -10px;
                justify-content: flex-start;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 20px;
                    margin-left: -40px;
                    margin-right: -40px;
                }

                @include media-breakpoint-up(xxxl) {
                    justify-content: space-between;
                }

                .movement-stat-stub {
                    margin-bottom: 20px;
                    padding: 0 10px;

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 40px;
                        padding: 0 40px;
                    }
                }
            }
        }

        &__text {
            transition-delay: 100ms;

            & > * {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.page-slide--in {
            .details-slide__title, .details-slide__lede, .quote-stub__text__comment, .quote-stub__text__punctuation, .quote-stub__author, .quote-stub__job-title, .details-slide__stats, .details-slide__text {
                opacity: 1;
                transform: translate(0);
            }
        }
    }
</style>