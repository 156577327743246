<template>
    <label class="base-input"
           :class="classes"
    >
        <span class="base-input__text">
            {{ label }}
        </span>
        <input :is="type"
               :value="value"
               @input="input"
               @focus="focus"
               @blur="blur"
               class="base-input__field"
        >
    </label>
</template>

<script>
    export default {
        props: {
            label: String,
            value: null,
            type: {
                type: String,
                default: 'input'
            }
        },

        data() {
            return {
                hasFocus: false
            }
        },

        computed: {
            classes() {
                let classes = [];

                if (this.hasFocus || this.value) {
                    classes.push('base-input--active');
                }

                if (this.type !== 'input') {
                    classes.push(`base-input--${this.type}`);
                }

                return classes;
            }
        },

        methods: {
            input(e) {
                this.$emit('input', e.target.value);
            },

            focus() {
                this.hasFocus = true;
            },

            blur() {
                this.hasFocus = false;
            }
        }
    }
</script>

<style lang="scss">
    .base-input {
        position: relative;
        display: block;
        transition: color 200ms;

        &--active {
            color: map-get($palette, "purple");

            .base-input__text {
                transform: scale(.75) translateY(-20px);

                @include media-breakpoint-up(lg) {
                    transform: scale(.75) translateY(-35px);
                }
            }
        }

        &--textarea {
            .base-input__text {
                top: 37px;
            }
        }

        &__text {
            display: block;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            transform-origin: left;
            transition: transform 200ms;
            text-transform: uppercase;
            letter-spacing: 1px;
            @include font-size(10);
            @include line-height(10);

            @include media-breakpoint-up(lg) {
                @include font-size(16);
                @include line-height(16);
            }
        }

        &__field {
            background-color: map-get($palette, "white");
            color: map-get($palette, "purple");
            border: none;
            outline: none !important;
            padding: 20px 20px 10px 20px;
            display: block;
            width: 100%;
            @include font-rhythm(12);

            @include media-breakpoint-up(lg) {
                padding: 30px 20px 20px 20px;
                @include font-rhythm(18);
            }
        }

        textarea {
            min-height: 230px;
        }
    }
</style>