<template>
    <div id="case-study">
        <PageHeader colour="purple"/>
        <SecondaryHeroSlide v-bind="hero"/>
        <component v-for="(slide, index) in slides"
                   :is="slide.type"
                   v-bind="slide.attributes"
                   :key="index"
        />
        <WorkSlide v-if="related && related.length"
                   :items="related"
        />
        <ContactSlide></ContactSlide>
    </div>
</template>

<script>
    import data from '../../../data';
    import PageHeader from '../partials/PageHeader';
    import SecondaryHeroSlide from '../../slides/SecondaryHeroSlide';
    import FeatureSlide from '../../slides/FeatureSlide';
    import DetailsSlide from '../../slides/DetailsSlide';
    import WorkSlide from '../../slides/WorkSlide';
    import ContactSlide from '../../slides/ContactSlide';

    export default {
        components: {
            PageHeader,
            SecondaryHeroSlide,
            FeatureSlide,
            DetailsSlide,
            WorkSlide,
            ContactSlide
        },

        props: {
            slug: String
        },

        data() {
            let match = data.caseStudies.find(caseStudy => {
                return caseStudy.lookupKey === this.slug;
            });

            if (match) {
                return match;
            }

            // 404
        },
    }
</script>

<style lang="scss">
</style>