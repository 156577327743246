<template>
    <div class="icon"
         :class="classes"
         @click="$emit('click')"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            colour: String,
            direction: String
        },

        computed: {
            classes() {
                let classes = [`icon--${this.colour}`];

                if (this.direction) {
                    classes.push(`icon--direction-${this.direction}`)
                }

                return classes;
            }
        }
    }
</script>

<style lang="scss">
    .icon {
        transform-origin: center;

        @each $name, $hex in $palette {
            &--#{$name} {
                line, polyline, path {
                    &.icon__symbol {
                        stroke: $hex;
                    }
                }

                polygon, path, rect {
                    &.icon__symbol {
                        fill: $hex;
                    }
                }
            }
        }

        &--direction-down {
            transform: rotate(180deg);
        }
    }
</style>