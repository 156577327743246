<template>
    <div class="inline-links-stub">
        <nav class="inline-links-stub__nav">
            <BaseLink v-for="(item, index) in items"
                      :key="index"
                      underlined
                      v-bind="item.attributes"
                      class="inline-links-stub__nav__item"
            >
                {{ item.text }}
            </BaseLink>
        </nav>
    </div>
</template>

<script>
    export default {
        props: {
            items: Array
        }
    }
</script>

<style lang="scss">
    .inline-links-stub {
        &__nav {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 25px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }

            &__item {
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
</style>