import Vue from '../providers/vue';

class Tracking {
    static enable() {
        Vue.$gtag.optIn();
    }

    static disable() {
        Vue.$gtag.optOut();
    }
}

export default Tracking;