<template>
    <div class="logo-wall">
        <div v-for="(logo, index) in logos"
             :key="index"
             class="logo-wall__brick"
             :class="[`logo-wall__brick--${logo.modifier}`]"
        >
            <SvgRenderer :name="logo.component"
                         colour="black"
            ></SvgRenderer>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                logos: [
                    {
                        component: 'LogoArtFund',
                        modifier: 'art-fund'
                    },
                    {
                        component: 'LogoBritishCouncil',
                        modifier: 'british-council'
                    },
                    {
                        component: 'LogoCHoareAndCo',
                        modifier: 'c-hoare-and-co'
                    },
                    {
                        component: 'LogoColumns',
                        modifier: 'columns'
                    },
                    {
                        component: 'LogoFindersKeepers',
                        modifier: 'finders-keepers'
                    },
                    {
                        component: 'LogoFortius',
                        modifier: 'fortius'
                    },
                    {
                        component: 'LogoHenryMoore',
                        modifier: 'henry-moore'
                    },
                    {
                        component: 'LogoHobsons',
                        modifier: 'hobsons'
                    },
                    {
                        component: 'LogoIMRG',
                        modifier: 'imrg'
                    },
                    {
                        component: 'LogoLloydsRegister',
                        modifier: 'lloydes-register'
                    },
                    {
                        component: 'LogoRedrow',
                        modifier: 'redrow'
                    },
                    {
                        component: 'LogoShell',
                        modifier: 'shell'
                    },
                    {
                        component: 'LogoTap',
                        modifier: 'tap'
                    },
                    {
                        component: 'LogoUN',
                        modifier: 'un'
                    },
                    {
                        component: 'LogoWaverton',
                        modifier: 'waverton'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss">
    .logo-wall {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px -20px -20px;

        @include media-breakpoint-up(md) {
            margin: 0 -40px -40px -40px;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 -50px -50px -50px;
        }

        &__brick {
            width: 33.33333%;
            padding: 6%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(md) {
                width: 25%;
                padding: 60px 3%;
            }

            @include media-breakpoint-up(xxl) {
                width: 20%;
                padding: 80px 4%;
            }

            &--british-council {
                padding-left: 5%;
                padding-right: 5%;

                @include media-breakpoint-up(md) {
                    padding-left: 4%;
                    padding-right: 4%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 5%;
                    padding-right: 5%;
                }
            }

            &--finders-keepers {
                padding-left: 4%;
                padding-right: 4%;

                @include media-breakpoint-up(md) {
                    padding-left: 2%;
                    padding-right: 2%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 2%;
                    padding-right: 2%;
                }

                .svg {
                    position: relative;
                    top: 4px;
                }
            }

            &--fortius {
                .svg {
                    position: relative;
                    top: -2px;
                }
            }

            &--henry-moore {
                padding-left: 9%;
                padding-right: 9%;

                @include media-breakpoint-up(md) {
                    padding-left: 8%;
                    padding-right: 8%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 6%;
                    padding-right: 6%;
                }
            }

            &--c-hoare-and-co {
                padding-left: 4%;
                padding-right: 4%;

                @include media-breakpoint-up(md) {
                    padding-left: 2%;
                    padding-right: 2%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 2%;
                    padding-right: 2%;
                }

                .svg {
                    position: relative;
                    top: 4px;
                }
            }

            &--lloydes-register {
                padding-left: 12%;
                padding-right: 12%;

                @include media-breakpoint-up(md) {
                    padding-left: 9%;
                    padding-right: 9%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 7%;
                    padding-right: 7%;
                }
            }

            &--shell {
                padding-left: 11%;
                padding-right: 11%;

                @include media-breakpoint-up(md) {
                    padding-left: 8%;
                    padding-right: 8%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 6%;
                    padding-right: 6%;
                }
            }

            &--un {
                padding-left: 11%;
                padding-right: 11%;

                @include media-breakpoint-up(md) {
                    padding-left: 8%;
                    padding-right: 8%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 6%;
                    padding-right: 6%;
                }
            }

            &--waverton {
                padding-left: 4%;
                padding-right: 4%;

                @include media-breakpoint-up(md) {
                    padding-left: 2%;
                    padding-right: 2%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: 3%;
                    padding-right: 3%;
                }
            }

            &--imrg {
                padding-left: 7%;
                padding-right: 7%;

                @include media-breakpoint-up(md) {
                    padding-left: 5%;
                    padding-right: 5%;
                }
            }

            .svg {
                width: 100%;
            }
        }
    }
</style>