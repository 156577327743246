<template>
    <CtaStub label="Give us a call">
        <BaseLink href="tel:+4420 7395 6535"
                  underlined
        >
            +44 (0)20 7395 6535
        </BaseLink>
    </CtaStub>
</template>

<script>
    import CtaStub from './CtaStub';

    export default {
        components: {
            CtaStub
        }
    }
</script>