<template>
    <BaseBox class="toggle-switch"
             :class="classes"
             :background-colour="backgroundColour"
             @click="click"
    >
        <BaseBox class="toggle-switch__draggable"
                 background-colour="white"
        >
            <IconRenderer name="tick"
                          colour="teal"
            />
        </BaseBox>
    </BaseBox>
</template>

<script>
    export default {
        props: {
            value: Boolean,
        },

        computed: {
            backgroundColour() {
                return this.value ? 'teal' : 'black-25';
            },

            classes() {
                return this.value ? ['toggle-switch--active'] : [];
            }
        },

        methods: {
            click() {
                this.$emit('input', !this.value)
            }
        }
    }
</script>

<style lang="scss">
    .toggle-switch {
        transition: background-color 900ms;
        padding: 2px;
        cursor: pointer;
        width: 70px;
        height: 38px;
        border-radius: 19px;

        &--active {
            .toggle-switch__draggable {
                transform: translateX(32px);

                .icon {
                    opacity: 1;
                    transition-delay: 250ms;
                }
            }
        }

        &__draggable {
            border-radius: 50%;
            width: 34px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 400ms;

            .icon {
                width: 65%;
                opacity: 0;
                transition: opacity 300ms;
            }
        }
    }
</style>