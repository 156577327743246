<template>
    <div class="default-layout">
        <div class="default-layout__content">
            <BaseBox background-colour="black">
                <PageHeader colour="white"
                            active-colour="yellow"
                />
                <HeaderStripe ref="headerStripe"/>
                <BaseBox text-colour="white"
                         padding="large"
                         class="pb-0"
                >
                    <BContainerInset>
                        <section class="default-layout__content__intro"
                                 ref="intro"
                        >
                            <BaseBox element="h1"
                                     text-colour="yellow"
                                     class="default-layout__content__intro__title"
                            >
                                <slot name="title"></slot>
                            </BaseBox>
                            <div class="default-layout__content__intro__summary">
                                <slot name="summary"></slot>
                            </div>
                        </section>
                        <div class="default-layout__content__slot">
                            <slot></slot>
                        </div>
                    </BContainerInset>
                </BaseBox>
            </BaseBox>
            <FooterStripeTip ref="stripeTip"/>
        </div>
        <ContactSlide :minimal="minimalFooter"
                      ref="contactSlide"
        ></ContactSlide>
    </div>
</template>

<script>
    import PageHeader from "../partials/PageHeader";
    import HeaderStripe from '../partials/stubs/HeaderStripe';
    import FooterStripeTip from '../partials/stubs/FooterStripeTip';
    import ContactSlide from '../../slides/ContactSlide';

    export default {
        components: {
            PageHeader,
            HeaderStripe,
            FooterStripeTip,
            ContactSlide
        },

        props: {
            minimalFooter: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                }
            }
        },

        mounted() {
            this.$refs.headerStripe.setAnchorContainer(this.$refs.intro);
            this.$refs.stripeTip.setAnchorContainer(this.$refs.contactSlide.$el.querySelector('.container-inset'));
        }
    }
</script>

<style lang="scss">
    .default-layout {
        &__content {
            position: relative;

            &__intro {
                margin-top: 120px;
                margin-bottom: 35px;

                @include media-breakpoint-up(md) {
                    margin-top: 160px;
                    margin-bottom: 60px;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: 210px;
                    margin-bottom: 60px;
                }

                @include media-breakpoint-up(xl) {
                    margin-top: 280px;
                    margin-bottom: 100px;
                }

                &__title, &__summary {
                    transform: translateX(-60px);
                    opacity: 0;
                }

                &__title {
                    color: map-get($palette, "yellow");
                    animation: reveal-default-layout-content 400ms 1200ms forwards;
                }

                &__summary {
                    max-width: 500px;
                    animation: reveal-default-layout-content 400ms 1300ms forwards;

                    > * {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &__slot {
                transform: translateX(-60px);
                opacity: 0;
                animation: reveal-default-layout-content 400ms 1400ms forwards;
            }

            table {
                color: map-get($palette, "black");
            }
        }
    }

    @keyframes reveal-default-layout-content {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>