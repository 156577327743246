<template>
    <div class="movement-stat-stub">
        <div class="movement-stat-stub__title">
            <slot></slot>
        </div>
        <div class="movement-stat-stub__movement">
            <div class="movement-stat-stub__movement__direction">
                <IconRenderer name="ArrowHeadUp"
                              colour="orange"
                              :direction="direction"
                />
            </div>
            <div class="movement-stat-stub__movement__value">
                {{ value }}
            </div>
        </div>
    </div>
</template>

<script>
    import IconRenderer from '../../../base/icons/IconRenderer';

    export default {
        components: {
            IconRenderer
        },

        props: {
            direction: String,
            value: null
        }
    }
</script>

<style lang="scss">
    .movement-stat-stub {
        &__title {
            text-transform: uppercase;
            color: map-get($palette, "orange");
            letter-spacing: 2px;
            margin-bottom: 5px;
            @include font-rhythm(10);

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                @include font-rhythm(16);
            }
        }

        &__movement {
            display: flex;
            align-items: center;

            &__direction {
                width: 15px;
                margin-right: 10px;
                position: relative;
                top: 1px;

                @include media-breakpoint-up(lg) {
                    width: 27px;
                    margin-right: 15px;
                    top: 6px;
                }
            }

            &__value {
                @include font-size(25);
                @include line-height(25);

                @include media-breakpoint-up(lg) {
                    @include font-size(80);
                    @include line-height(80);
                }
            }
        }
    }
</style>