<template>
    <component :is="name"
               class="svg"
               :class="classes"
    ></component>
</template>

<script>
    export default {
        props: {
            name: String,
            colour: {
                type: String,
                default: 'white'
            }
        },

        computed: {
            classes() {
                return [`svg--${this.colour}`]
            }
        }
    }
</script>

<style lang="scss">
    .svg {
        @each $name, $hex in $palette {
            &--#{$name} {
                fill: $hex;
            }
        }
    }
</style>