<template>
    <BaseBox id="cookie-consent"
             padding="large"
             background-colour="black"
             text-colour="white"
             :class="classes"
    >
        <bContainerInset>
            <div id="cookie-consent__content">
                <div id="cookie-consent__content__text">
                    <h6 id="cookie-consent__content__text__heading">
                        It's all about you
                    </h6>
                    <p>
                        We use cookies and other similar tech to help provide you with the best experience possible.
                    </p>
                    <BaseBtn border-colour="yellow"
                             text-colour="white"
                             @click="accept"
                             class="d-lg-none"
                             id="cookie-consent__content__accept-mobile"
                    >
                        Im happy with that
                    </BaseBtn>
                    <p class="mb-0">
                        To find out more about the hows and whys, head over to our
                        <BaseLink route="cookie-policy"
                                  class="d-xl-none"
                                  id="cookie-consent__content__text__cta-mobile"
                        >
                            Cookies page.
                        </BaseLink>
                        <BaseLink route="cookie-policy"
                                  underlined
                                  id="cookie-consent__content__text__cta-desktop"
                                  class="d-none d-xl-inline-block"
                        >
                            Cookies page.
                        </BaseLink>
                    </p>
                </div>
                <BaseBtn border-colour="yellow"
                         text-colour="white"
                         @click="accept"
                         id="cookie-consent__content__accept-desktop"
                         class="d-none d-lg-block"
                >
                    Im happy with that
                </BaseBtn>
            </div>
        </bContainerInset>
    </BaseBox>
</template>

<script>
    export default {
        data() {
            return {
                ready: false
            }
        },

        computed: {
            classes() {
                return !this.$store.getters['consent/accepted'] && this.ready ? ['cookie-consent--in'] : [];
            }
        },

        methods: {
            accept() {
                this.$store.commit('consent/accept');
                this.$store.commit('consent/acceptAnalytics');
            }
        },

        mounted() {
            window.setTimeout(() => {
                this.ready = true;
            }, 3000)
        }
    }
</script>

<style lang="scss">
    #cookie-consent {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        transition: transform 400ms;
        z-index: 1;
        @include font-rhythm(16);

        &.cookie-consent--in {
            transform: translateY(0);
        }

        &__content {
            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
            }

            &__text {
                @include media-breakpoint-up(md) {
                    flex-grow: 1;
                }

                &__heading {
                    font-size: inherit;
                }

                &__cta-mobile {
                    text-decoration: underline;
                }

                &__cta-desktop {
                    vertical-align: middle;
                }
            }

            &__accept-mobile {
                margin: 20px 0;
            }

            &__accept-desktop {
                margin-left: 20px;
            }

            .base-btn {
                white-space: nowrap;
            }

            p {
                margin-bottom: 10px;
            }
        }
    }
</style>