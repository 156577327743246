export default {
    namespaced: true,

    state: {
        in: null
    },

    getters: {
        in(state) {
            return state.in;
        }
    },

    mutations: {
        in(state) {
            state.in = true;
        },

        out(state) {
            state.in = false;
        }
    }
}