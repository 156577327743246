<template>
    <div class="case-study-poster"
    >
        <router-link :to="route"
                     class="case-study-poster__assets"
        >
            <div class="case-study-poster__assets__img">
                <BaseImg :src="imgSrc"></BaseImg>
            </div>
            <div class="case-study-poster__assets__overlay"></div>
            <div class="case-study-poster__assets__logo">
                <SvgRenderer :name="logoComponent"/>
            </div>
            <div class="case-study-poster__assets__fold"></div>
        </router-link>
        <div class="case-study-poster__summary" v-html="summary"></div>
    </div>
</template>

<script>
    export default {
        props: {
            imgSrc: String,
            logo: String,
            summary: String,
            slug: String
        },

        computed: {
            logoComponent() {
                return `Logo${this.logo}`;
            },

            route() {
                return {
                    name: 'case-study',
                    params: {
                        slug: this.slug
                    }
                }
            }
        },

        methods: {
            click() {
                if (this.route) {
                    this.$router.push({ name : this.route })
                }
            }
        }
    }
</script>

<style lang="scss">
    .case-study-poster {
        &__assets {
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
            display: block;

            @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
            }

            &:hover {
                .case-study-poster__assets__img {
                    transform: scale(1.1);
                }

                .case-study-poster__assets__fold {
                    border-width: 100px 100px 0 0;
                }
            }

            &__img {
                transition: transform 500ms;
            }

            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: map-get($palette, "black");
                opacity: .7;
            }

            &__logo {
                width: 55%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &__fold {
                width: 0;
                height: 0;
                position: absolute;
                bottom: 0;
                right: 0;
                border-width: 0;
                border-style: solid;
                background: map-get($palette, "purple");
                display:block;
                border-color:transparent map-get($palette, "black") transparent map-get($palette, "black");
                transition: border-width 0.2s ease-out;
            }
        }
    }
</style>