import VueRouter from 'vue-router';
import routes from '../routes';
import store from './store';

const router = new VueRouter({
    mode: 'history',
    routes
});

// set a revealer delay to prevent the browser from stuttering on initial page load
let delay = 1000;

router.beforeEach((to, from, next) => {
    window.setTimeout(() => {
        delay = 0;
        store.commit('revealer/in');
    }, delay);

    // we need to wait for the revealer to animate in
    window.setTimeout(() => {
        window.scrollTo(0, 0);
        next();
    }, delay + 1000);
});

router.afterEach(() => {
    // Add a small delay so as not to overload the browser
    window.setTimeout(() => {
        store.commit('revealer/out');
    }, 500);
});

export default router;