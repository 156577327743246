<template>
    <BaseBox v-bind="passthrough"
             class="page-slide"
             :class="classes"
    >
        <slot></slot>
    </BaseBox>
</template>

<script>
    export default {
        props: {
            backgroundColour: String,
            textColour: String,
            fullHeight: {
                type: Boolean,
                default: false
            },
            debug: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                in: false
            }
        },

        computed: {
            passthrough() {
                return {
                    backgroundColour: this.backgroundColour,
                    textColour: this.textColour
                }
            },

            classes() {
                let classes = [];

                if (this.in) {
                    classes.push('page-slide--in');
                }

                if (this.fullHeight) {
                    classes.push('page-slide--full-height');
                }

                return classes;
            }
        },

        mounted() {
            window.setTimeout(() => {
                let intersectionObserver = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (this.debug) {
                            console.log(entry);
                        }

                        if (entry.isIntersecting) {
                            this.in = true;

                            this.$emit('in');
                        }
                    });
                }, {
                    rootMargin: '0px 0px -30% 0px',
                });

                intersectionObserver.observe(this.$el);
            }, 500);
        }
    }
</script>

<style lang="scss">
    .page-slide {
        position: relative;

        &--full-height {
            min-height: 100vh;
        }
    }
</style>