<template>
    <BaseBox padding="large"
             class="signoff-stub"
    >
        <BContainerInset>
            <BRow>
                <BCol class="text-right">
                    <SocialStub/>
                </BCol>
            </BRow>
            <BRow>
                <BCol lg="4"
                      order-lg="2"
                      class="text-right"
                >
                    <div class="signoff-stub__policies">
                        <PoliciesStub/>
                    </div>
                </BCol>
                <BCol lg="4"
                      order-lg="1"
                >
                    <CopyrightStub/>
                </BCol>
            </BRow>
        </BContainerInset>
    </BaseBox>
</template>

<script>
    import SocialStub from './SocialStub';
    import PoliciesStub from './PoliciesStub';
    import CopyrightStub from './CopyrightStub';

    export default {
        components: {
            SocialStub,
            PoliciesStub,
            CopyrightStub
        }
    }
</script>

<style lang="scss">
    .signoff-stub {
        color: map-get($palette, "white");

        &__policies {
            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 100%;
            }
        }
    }

    #home-page {
        .signoff-stub {
            @include media-breakpoint-up(lg) {
                padding: 20px 40px 0 40px;
            }

            @include media-breakpoint-up(xl) {
                padding: 40px;
            }
        }
    }
</style>
