<template>
    <BaseBox class="contact-slide"
             background-colour="black"
             ref="outerContainer"
             :class="classes"
    >
        <BaseBox text-colour="white"
                 class="contact-slide__work-with-us pt-0 pb-0"
                 padding="large"
        >
            <BContainerInset ref="innerContainer">
                <BRow v-if="!minimal">
                    <BCol>
                        <h5 class="h1">
                            Let's work<br>together
                        </h5>
                    </BCol>
                </BRow>
                <BRow>
                    <BCol lg="4">
                        <EmailCtaStub v-if="!minimal"/>
                    </BCol>
                    <BCol lg="4"
                          order-lg="3"
                    >
                        <!--<HiringCtaStub v-if="!minimal"/>-->
                        <TelephoneCtaStub v-if="!minimal"/>
                    </BCol>
                    <BCol lg="4"
                          order-lg="2"
                    >
                        <div class="contact-slide__inline">
                            <div class="contact-slide__logo">
                                <LogoOsSmall/>
                            </div>
                        </div>
                    </BCol>
                    <BCol lg="4"
                          order-lg="4"
                          class="text-right"
                    >
                        <AddressStub/>
                    </BCol>
                </BRow>
            </BContainerInset>
        </BaseBox>
        <SignoffStub class="contact-slide__signoff pb-0"/>
        <ContentClipper ref="contentClipper"
                        class="contact-slide__background-clipper"
        >
            <BaseBox background-colour="purple"
                     class="contact-slide__background"
            ></BaseBox>
        </ContentClipper>
    </BaseBox>
</template>

<script>
    import AddressStub from '../views/partials/stubs/AddressStub';
    import TelephoneCtaStub from '../views/partials/stubs/TelephoneCtaStub';
    import EmailCtaStub from '../views/partials/stubs/EmailCtaStub';
    // import HiringCtaStub from '../views/partials/stubs/HiringCtaStub';
    import SignoffStub from '../views/partials/stubs/SignoffStub';
    import ContentClipper from '../views/partials/ContentClipper';

    export default {
        components: {
            AddressStub,
            TelephoneCtaStub,
            EmailCtaStub,
            // HiringCtaStub,
            SignoffStub,
            ContentClipper
        },

        props: {
            minimal: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            classes() {
                return this.minimal ? ['contact-slide--minimal'] : [];
            }
        },

        mounted() {
            this.$refs.contentClipper.setAnchorContainer(this.$refs.innerContainer.$el);
            this.$refs.contentClipper.setHeightContainer(this.$refs.outerContainer.$el);
        }
    }
</script>

<style lang="scss">
    .contact-slide {
        position: relative;
        padding: 50px 0;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
            padding: 130px 0;
        }

        &--minimal {
            padding-top: 300px;

            @include media-breakpoint-up(sm) {
                padding-top: 280px;
            }

            @include media-breakpoint-up(md) {
                padding-top: 350px;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 500px;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 350px;
            }
        }

        a {
            color: map-get($palette, "white") !important;
        }

        .h1 {
            color: map-get($palette, "yellow");
        }

        &__inline {
            display: flex;
            justify-content: space-between;
            align-content: flex-end;
        }

        &__logo {
            margin-left: auto;
        }

        .logo-os-small {
            margin-top: 45px;
            margin-bottom: 30px;

            @include media-breakpoint-up(xl) {
                margin-top: 30px;
                margin-bottom: 95px;
            }
        }

        &__background-clipper {
            z-index: 0;
            top: 200px;

            @include media-breakpoint-up(sm) {
                top: 0;
            }
        }

        &__work-with-us, &__signoff {
            position: relative;
            z-index: 1;
        }

        &__background {
            width: 100%;
            height: 100%;
        }
    }
</style>