// Styles
import './styles/app.scss';

// Js
import Vue from './providers/vue';
import App from './App.vue';
import router from './providers/router';
import store from './providers/store';
import VueGtag from "vue-gtag";
import './providers/api';

Vue.use(VueGtag, {
    config: { id: "G-1PWWD8PEZN" },
    enabled: store.getters['consent/analytics']
}, router);

// Vue instance
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
