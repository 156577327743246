<template>
    <div class="base-form-field">
        <div v-if="error"
             class="base-form-field__error"
        >
            {{ error }}
        </div>
        <div class="base-form-field__input">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            error: String
        }
    }
</script>

<style lang="scss">
    .base-form-field {
        &__error {
            margin-bottom: 10px;
            color: map-get($palette, "purple-50");
        }
    }
</style>