<template>
    <BaseBox id="mobile-nav"
             :class="classes"
             background-colour="black"
             padding="large"
    >
        <nav id="mobile-nav__items">
            <BaseLink v-for="(item, index) in items"
                      :key="index"
                      :route="item.route"
                      colour="white"
                      activeColour="yellow"
                      class="mobile-nav__items__item"
            >
                {{ item.title }}
            </BaseLink>
        </nav>
        <IconRenderer id="mobile-nav__close"
                      name="cross"
                      @click="emitClose"
        ></IconRenderer>
    </BaseBox>
</template>

<script>
    export default {
        props: {
            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                items: [
                    {
                        route: 'home',
                        title: 'Home'
                    },
                    {
                        route: 'work',
                        title: 'Work'
                    },
                    {
                        route: 'careers',
                        title: 'Careers'
                    },
                    {
                        route: 'contact',
                        title: 'Contact'
                    }
                ]
            }
        },

        computed: {
            classes() {
                return this.active ? ['mobile-nav--active'] : [];
            }
        },

        methods: {
            emitClose() {
                this.$emit('close');
            }
        }
    }
</script>

<style lang="scss">
    #mobile-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100% - 20px);
        height: 100vh;
        transform: translateX(-100%);
        transition: transform 500ms cubic-bezier(0.55, 0.055, 0.675, 0.19);

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &.mobile-nav--active {
            transform: translateY(0);

            .mobile-nav__items__item {
                opacity: 1;
                transform: translateX(0);

                &:first-child {
                    transition-delay: 500ms;
                }

                &:nth-child(2) {
                    transition-delay: 600ms;
                }

                &:nth-child(3) {
                    transition-delay: 700ms;
                }

                &:last-child {
                    transition-delay: 800ms;
                }
            }

            #mobile-nav__close {
                opacity: 1;
                transform: none;
            }
        }

        &__close {
            width: 20px;
            position: absolute;
            top: 30px;
            right: 20px;
            opacity: 0;
            transform: scale(.1);
            transition: opacity 1000ms 600ms, transform 230ms 700ms;
        }

        &__items {
            display: flex;
            flex-direction: column;
            @include font-rhythm(24);

            .mobile-nav__items__item {
                opacity: 0;
                transition: opacity 1200ms, transform 400ms;
                transform: translateX(-40px);
                padding: 10px 0;
                text-decoration: none !important;
            }
        }
    }
</style>