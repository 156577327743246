<template>
    <main id="content">
        <slot></slot>
    </main>
</template>

<script>
    export default {

    }
</script>

<style lang="scss">
    #content {
        flex-grow: 1;
    }
</style>