<template>
    <MasterLayout>
        <router-view :key="$route.fullPath"></router-view>
    </MasterLayout>
</template>

<script>
    import MasterLayout from './components/views/layouts/MasterLayout';

    export default {
        components: {
            MasterLayout
        }
    }
</script>