import Cookies from 'js-cookie';
import Tracking from '../../services/tracking';

function persist() {
    Cookies.set('consent', state);
}

let persisted = Cookies.get('consent');

let state = persisted ? JSON.parse(persisted) : {
    accepted: false,
    analytics: false
};

export default {
    namespaced: true,

    state,

    getters: {
        accepted(state) {
            return state.accepted;
        },

        analytics(state) {
            return state.analytics;
        }
    },

    mutations: {
        accept(state) {
            state.accepted = true;

            persist();
        },

        acceptAnalytics(state) {
            state.analytics = true;

            persist();
            Tracking.enable();
        },

        declineAnalytics(state) {
            state.analytics = false;

            persist();
            Tracking.disable();
        }
    }
};