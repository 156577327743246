<template>
    <div id="privacy-page">
        <DefaultLayout>
            <template slot="title">
                Your Privacy
            </template>
            <template slot="summary">
                Time for the science bit! The text below outlines how Online Solutions obtain, store and process any personal information gathered through interaction with our website.
            </template>
            <BaseTextbox id="privacy-page__content">
                <h2>
                    Why we need your data and what we do with it
                </h2>
                <p>
                    Online Solutions will be what’s known as the ‘Controller’ of the personal data you provide to us. To allow us to provide you with your chosen service, we may be required to collect sensitive data. However, we will not collect any data about you that we do not need in order to provide you with a suitable and appropriate investment service.
                </p>
                <h2>
                    The types of data we may collect about you
                </h2>
                <p>
                    By using the “Send us a message” function on our website, we will be collecting your name, your email address, your phone number (optional) and any information that you chose to send us in your message. By browsing the website however, we will only ever be collecting information about your computer and/or mobile device and information about how you use our website (eg which pages you have viewed, the time you viewed them).
                </p>
                <h2>
                    Cookies
                </h2>
                <p>
                    Cookies are small files which are sent by us to your computer or other access device. Cookies can help to improve your experience when you use the website, and generally help us maintain and improve the website for all users. On this website we only use analytic cookies used to collect basic information about your visit (eg which pages were visited, or what order the pages were viewed). We do not use cookies to retrieve any personal information about you. You may choose to stop receiving cookies at any time through your web browser. If you want to delete any cookies that are already on your computer, please refer to the instructions for your file management software to locate the file or directory that stores cookies. If you want to stop cookies being stored on your computer in future, please refer to your browser manufacturer's instructions by clicking "help" in your browser menu.
                </p>
                <h2>
                    Google analytics
                </h2>
                <p>
                    We use Google Analytics to analyse our website usage and create reports for internal use only.
                </p>
                <h2>
                    Security of information
                </h2>
                <p>
                    Online Solutions has implemented accepted standards of security policies and controls that are aimed at protecting the personal data it has under its control from unauthorised access, improper use or disclosure, unauthorised modification and unlawful destruction or accidental loss.
                </p>
                <h2>
                    What we do with your data
                </h2>
                <p>
                    Only Online Solutions employees process person data we receive and no third parties have access to your personal data unless the law allows them to do so. Online Solutions does not collect or compile personal data or information obtained through the website for dissemination or sale to outside parties or undertake marketing activities for third parties.
                </p>
                <h2>
                    Marketing
                </h2>
                <p>
                    We may use your personal information to tell you about relevant products and offers. This is what we mean when we talk about ‘marketing’. Personal information that we use for marketing purposes will be kept with us until you notify us that you no longer wish to receive this information. You can ask us to stop sending you marketing messages by contacting us at any time. If at any point you believe the information we process on you is incorrect you can request to see this information and have it corrected or deleted. If you wish to raise a complaint on how we have handled your personal data, you can contact Online Solutions by emailing <a href="mailto:hello@onlinesolutionsltd.com">hello@onlinesolutionsltd.com</a> to have the matter investigated.
                </p>
                <h2>
                    Changes to this privacy policy
                </h2>
                <p>
                    Online Solutions has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage you to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
                </p>
                <h2>
                    Your acceptance of this policy
                </h2>
                <p>
                    By using this website, you signify your acceptance of our Privacy Policy. If you do not agree to this Privacy Policy, please do not use our website. We reserve the right, at our discretion, to change, modify, add, or remove portions from this policy at any time so visitors are encouraged to review this policy from time to time. We will of course notify you of any changes where we are required to do so. Your continued use of our website following the posting of changes to these terms means you accept these changes.
                </p>
                <p>
                    This document was last updated on 11 Dec 2020
                </p>
            </BaseTextbox>
        </DefaultLayout>
    </div>
</template>

<script>
    import DefaultLayout from '../layouts/DefaultLayout';

    export default {
        components: {
            DefaultLayout
        },
    }
</script>

<style lang="scss">
    #privacy-page {
    }
</style>