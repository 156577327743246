<template>
    <div id="careers-page">
        <DefaultLayout>
            <template slot="title">
                Careers
            </template>
            <template slot="summary">
                We're often looking for talented and dynamic individuals to join our team here in London Bridge, so keep an eye on this page and we'll let you know as and when we have any vacancies available
            </template>
            <BContainer>
                <BRow>
                    <BCol>
                        <div id="careers-page__no-vacancies">
                            <h2 id="careers-page__no-vacancies__title">
                                Sorry guys, we're not currently hiring at the moment
                            </h2>
                            <p>
                                Please check back again soon
                            </p>
                        </div>
                    </BCol>
                </BRow>
                <!--<BRow>-->
                    <!--<BCol xl="4">-->
                        <!--<div id="careers-page__form">-->
                            <!--<h2>-->
                                <!--Interested?-->
                            <!--</h2>-->
                            <!--<BaseInput v-model="form.name"-->
                                       <!--label="Name"-->
                            <!--/>-->
                            <!--<BaseInput v-model="form.email"-->
                                       <!--label="Email address"-->
                            <!--/>-->
                            <!--<BaseInput v-model="form.phone"-->
                                       <!--label="Phone (optional)"-->
                            <!--/>-->
                            <!--<BaseInput v-model="form.cv"-->
                                       <!--label="CV"-->
                            <!--/>-->
                            <!--<BaseBtn border-colour="purple"-->
                                     <!--text-colour="black"-->
                            <!--&gt;-->
                                <!--Send Message-->
                            <!--</BaseBtn>-->
                        <!--</div>-->
                    <!--</BCol>-->
                    <!--<BCol xl="3" offset-xl="1">-->
                        <!--<EmailCtaStub/>-->
                        <!--<TelephoneCtaStub class="mb-0"/>-->
                    <!--</BCol>-->
                <!--</BRow>-->
            </BContainer>
        </DefaultLayout>
    </div>
</template>

<script>
    import DefaultLayout from '../layouts/DefaultLayout';
    // import TelephoneCtaStub from '../partials/stubs/TelephoneCtaStub';
    // import EmailCtaStub from '../partials/stubs/EmailCtaStub';

    export default {
        components: {
            DefaultLayout,
            // TelephoneCtaStub,
            // EmailCtaStub
        },

        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    cv: ''
                }
            }
        }
    }
</script>

<style lang="scss">
    #careers-page {
        &__no-vacancies {
            @include media-breakpoint-up(xl) {
                min-height: 400px;
            }

            &__title {
                margin-bottom: 20px;
            }
        }

        &__form {
            margin-bottom: 35px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }
    }
</style>