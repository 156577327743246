<template>
    <div id="sustainability-page">
        <DefaultLayout>
            <template slot="title">
                Sustainability
            </template>
            <template slot="summary">
                Sustainability is not about moving backwards but about looking forwards to new, better ways of doing things.
            </template>
            <article>
                <BaseTextbox id="privacy-page__content">
                    <h2>
                        What we are doing about it
                    </h2>
                    <p>
                        As we all know, sustainability is becoming a key issue for businesses and society in general, as people realise that current industrial, economic and social systems are storing up problems for the future. At Online Solutions we aim to do our best to work towards sustainability. We’re not perfect, but it is something that we take very seriously. We have a range of environmental and social strategies to help us reduce our negative impact and give something positive back to the world.
                    </p>
                    <h2>
                        Environmental
                    </h2>
                    <ul>
                        <li>
                            We minimise our carbon footprint by operating a virtual business structure, centred around our creative studio in London Bridge. This allows our team to collaborate with colleagues and clients from convenient locations and avoid excess travel. We also use the most efficient, practical mode of transport for each journey and have a no-fly policy, instead working with international clients, staff and partners using web communications
                        </li>
                        <li>
                            We only use paper from sustainable sources and avoid printing where we can. We only use the products and stationary that we need and recycle as much of our waste as is physically possible
                        </li>
                        <li>
                            Computers and other electrical appliances are turned off when not in use and we utilise energy saving functions wherever possible
                        </li>
                        <li>
                            All of our cleaning products are natural and/or biodegradable and our sustenance facilities (tea, coffee, milk etc.) are Fairtrade. We also utilise bulk purchasing to minimise packaging
                        </li>
                        <li>
                            As a business, Online Solutions primarily develop digital services which means they can be delivered with no physical goods production or transportation
                        </li>
                    </ul>
                    <h2>
                        Social
                    </h2>
                    <ul>
                        <li>
                            We value every member of our team and we make sure that they enjoy their work by giving them every possible opportunity to express themselves, fulfil their potential and fit their work around the other important aspects of their lives
                        </li>
                        <li>
                            We maintain healthy relationships with our customers and accommodate their needs wherever possible
                        </li>
                        <li>
                            We use local suppliers where possible to reduce our carbon footprint and stimulate the local economy
                        </li>
                        <li>
                            We only deal with ethical organisations, who treat their staff well
                        </li>
                        <li>
                            We comply with all equal opportunities laws and regulations
                        </li>
                    </ul>
                    <p id="sustainability-page__signoff">
                        As with everything, our commitment to the environment, sustainability and our carbon footprint is an ongoing effort and we are always on the look out for better ways of doing things. No one is perfect, but if we all strive to do everything we can to help change our own little corner of the planet, we can all look forward to a brighter future.
                    </p>
                </BaseTextbox>
            </article>
        </DefaultLayout>
    </div>
</template>

<script>
    import DefaultLayout from '../layouts/DefaultLayout';

    export default {
        components: {
            DefaultLayout
        },
    }
</script>

<style lang="scss">
    #sustainability-page {
        &__signoff {
            margin-top: 60px;
        }
    }
</style>
