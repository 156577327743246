<template>
    <CtaStub label="Send us an email">
        <BaseLink href="mailto:hello@onlinesolutionsltd.com"
                  underlined
        >
            Hello@onlinesolutionsltd.com
        </BaseLink>
    </CtaStub>
</template>

<script>
    import CtaStub from './CtaStub';

    export default {
        components: {
            CtaStub
        }
    }
</script>