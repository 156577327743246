<template>
    <BaseBox class="content-clipper"
             :style="styles"
    >
        <slot></slot>
    </BaseBox>
</template>

<script>
    export default {
        data() {
            return {
                windowWidth: null,
                heightContainer: null,
                height: null,
                anchorContainer: null,
                anchorWidth: null
            }
        },

        computed: {
            styles() {
                if (this.windowWidth > 1399) {
                    let gutter = (this.windowWidth - this.anchorWidth) / 2;
                    let width = this.height + gutter;
                    let path = `polygon(${this.height}px 0, 100% 0, 100% 100%, 0% 100%)`;

                    return {
                        width: `${width}px`,
                        clipPath: path,
                        webkitClipPath: path
                    };
                } else {
                    let path = `polygon(0 ${this.windowWidth}px, 100% 0%, 100% 100%, 0% 100%)`;

                    return {
                        width: null,
                        clipPath: path,
                        webkitClipPath: path
                    };
                }
            },
        },
        
        methods: {
            setAnchorContainer(container) {
                this.anchorContainer = container;
                this.anchorWidth = container.offsetWidth;
                
                return this;
            },

            setHeightContainer(container) {
                this.heightContainer = container;
                this.height = container.offsetHeight;

                return this;
            }
        },

        mounted() {
            this.windowWidth = document.body.clientWidth;

            window.addEventListener('resize', () => {
                this.windowWidth = document.body.clientWidth;
                this.anchorWidth = this.anchorContainer.offsetWidth;
                this.height = this.heightContainer.offsetHeight;
            });
        }
    }
</script>

<style lang="scss">
    .content-clipper {
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
    }
</style>