<template>
    <nav id="desktop-nav">
        <BaseLink v-for="(item, index) in items"
                  :key="index"
                  underlined
                  :route="item.route"
                  :colour="colour"
                  :activeColour="activeColour"
                  class="desktop-nav__item"
        >
            {{ item.title }}
        </BaseLink>
    </nav>
</template>

<script>
    import data from '../../../data';

    export default {
        props: {
            colour: {
                type: String,
                default: 'white'
            },
            activeColour: {
                type: String,
                default: 'purple'
            }
        },

        data() {
            return {
                items: data.nav
            }
        }
    }
</script>

<style lang="scss">
    #desktop-nav {
        display: none;

        @include media-breakpoint-up(lg) {
            justify-content: space-between;
            width: 430px;
            display: flex;
        }

        @include media-breakpoint-up(xxl) {
            width: 650px;
        }

        .desktop-nav__item {
            transform: translateX(-30px);
            opacity: 0;
            animation: reveal-nav-item 400ms forwards;

            @for $i from 1 through 15 {
                &:nth-child(#{$i}) {
                    animation-delay: 1500ms+($i * 90ms);
                }
            }
        }

        @keyframes reveal-nav-item {
            100% {
                transform: none;
                opacity: 1;
            }
        }
    }
</style>