<template>
    <PageSlide background-colour="black-25"
               class="clients-slide"
    >
        <BaseBox padding="large py-0">
            <div class="clients-slide__content">
                <div class="clients-slide__text">
                    <h2 class="clients-slide__text__title h1">
                        Our clients
                    </h2>
                    <p class="clients-slide__text__summary">
                        We love a logo and here’s a few from some of our clients. The OS team are extremely proud to partner with &hellip;
                    </p>
                </div>
                <BContainerInset>
                    <LogoWallStub/>
                </BContainerInset>
            </div>
        </BaseBox>
    </PageSlide>
</template>

<script>
    import PageSlide from './PageSlide';
    import LogoWallStub from '../views/partials/stubs/LogoWallStub';

    export default {
        components: {
            PageSlide,
            LogoWallStub
        }
    }
</script>

<style lang="scss">
    .clients-slide {
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 50px 0;
        justify-content: center;

        @include media-breakpoint-up(md) {
            padding: 120px 50px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 100vh;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
        }

        &__text {
            max-width: 710px;
            margin: 0 auto;

            &__title, &__summary {
                opacity: 0;
                transform: translateY(100px);
                transition: opacity 400ms, transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
            }


            &__summary {
                transition-delay: 100ms;
            }
        }

        .logo-wall__brick {
            opacity: 0;
            transform: translateX(40px);
            transition: opacity 1700ms, transform 400ms;

            @for $i from 1 through 15 {
                &:nth-child(#{$i}) {
                    transition-delay: 200ms+($i * 30ms);
                }
            }
        }
    }

    .page-slide--in {
        .clients-slide__text__title, .clients-slide__text__summary, .logo-wall__brick {
            opacity: 1;
            transform: translate(0);
        }
    }
</style>