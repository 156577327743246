<template>
    <component :is="component"
               :src="src"
               ref="component"
    ></component>
</template>

<script>
    export default {
        props: {
            type: String,
            src: String
        },

        computed: {
            component() {
                switch (this.type) {
                    case 'img':
                        return 'BaseImg';

                    case 'video':
                        return 'BaseVideo';

                    default:
                        return '';
                }
            }
        },

        methods: {
            getComponent() {
                return this.$refs.component;
            }
        }
    }
</script>