<template>
    <div class="cta-stub">
        <h6 v-html="label"
            class="cta-stub__label h2"
        ></h6>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            label: String
        }
    }
</script>

<style lang="scss">
    .cta-stub {
        color: map-get($palette, "white");
        margin-bottom: 30px;

        &__label {
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
                margin-bottom: 10px;
            }
        }
    }
</style>